<template>
  <div
    :class="`${layout === 'list' ? 'p-mb-3' : ''} ${
      loading ? 'header-margin' : ''
    } ${showFilter ? 'p-jc-between' : 'p-jc-end'} p-d-flex p-ai-center`"
  >
    <div v-if="showFilter">
      <Button
        v-show="!loading"
        label="Filter"
        icon="pi pi-angle-down"
        icon-pos="right"
        :class="`${layout === 'list' ? 'p-mx-0' : 'p-button-outlined'}
							p-button-secondary p-button-outlined filter-button`"
        @click="$emit('openFilter')"
      />
    </div>
    <div v-show="!loading && length > 0" class="mobv">
      <Button
        icon="pi pi-bars"
        :class="layout === 'list' ? 'p-mx-1' : 'p-button-outlined'"
        @click="toggleView('list')"
      />
      <Button
        icon="pi pi-th-large"
        :class="layout === 'grid' ? 'p-mx-1' : 'p-button-outlined'"
        @click="toggleView('grid')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'grid',
    },
    length: {
      type: Number,
      default: 0,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleView', 'openFilter'],
  methods: {
    toggleView(view) {
      this.$emit('toggleView', view);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-margin {
  margin-bottom: 34px;
}
</style>
